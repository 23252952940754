import { useEffect, useState } from "react";
import PaginatedItems from "./Pagination";
import Loading from "./Loading";
import MovieItems from "./MovieItems";

export default function MoviesBox({ query, onChangePage, searchQuery, onSearchQuery }) {
  const [movieList, setMovieList] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    async function fetchingMoviesList() {
      try {
        const res = await fetch(`https://moviesapi.ir/api/v1/movies?${searchQuery ? `q=${searchQuery}` : `&page=${query}`}`);

        if (!res.ok) throw new Error("Somthing went wrong with fetch movies");

        const data = await res.json();

        if (!data.data.length) throw new Error(`متاسفانه فیلمی به نام  ${searchQuery} پیدا نمی کنم !`);

        setError("");
        setMovieList(data.data);
        setMetaData(data.metadata);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }
    fetchingMoviesList();
  }, [query, onChangePage, searchQuery]);

  return (
    <>
      <section className="relative flex flex-col items-center justify-between w-full h-full p-8 space-y-10 border-4 select-none rounded-3xl border-dark_primary/40 inner__shadow ">
        <div className="w-full h-full p-5 rounded-2xl">
          {isLoading && <Loading />}
          {error && (
            <div className="grid w-full h-full place-content-center">
              <span className="font-bold text-red-600">{error.message}</span>
            </div>
          )}
          {!isLoading && !error && (
            <article className="grid h-auto grid-cols-1 gap-16 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-y-20 ">
              {movieList.map((movie) => (
                <MovieItems movie={movie} key={movie.id} />
              ))}
            </article>
          )}
        </div>

        <div className=" flex flex-row-reverse w-full items-center justify-center py-2.5 space-x-2">
          <PaginatedItems itemsPerPage={metaData.page_count} query={query} onChangePage={onChangePage} onSearchQuery={onSearchQuery} />
        </div>
      </section>
    </>
  );
}
