import { useState } from "react";

export default function Navbar({ onSearchQuery }) {
  const [searchInput, setSearchInput] = useState("");

  function handleSearchQuery(e) {
    e.preventDefault();
    onSearchQuery(searchInput);
  }

  return (
    <div className="flex flex-col items-center md:flex-row space-y-4 md:space-y-0 *:h-12 px-4 py-6 md:py-12 border-4 select-none md:px-5 xl:px-10 inner__shadow rounded-3xl border-dark_primary/40 ">
      <div className="flex w-full justify-evenly md:justify-start gap-x-4">
        <span className="flex items-center justify-center px-4 text-xl text-secondary font-Morabba rounded-2xl outer__shadow">مای فلیکس</span>

        <div className="flex items-center justify-center transition cursor-pointer w-fit text-secondary rounded-2xl hover:inner__shadow hover:text-yellow-600 outer__shadow">
          <div className="flex items-center px-4 py-3 gap-x-3">
            <span className="hidden md:block">ثبت نام / ورود </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <form className="w-full md:w-6/12 xl:w-5/12 2xl:w-3/12 " onSubmit={handleSearchQuery}>
        <div className="flex flex-row-reverse justify-between w-full px-4 py-1 transition bg-transparent outline-none rounded-2xl text-secondary outer__shadow focus-within:inner__shadow">
          <input className="w-full px-3 py-2 bg-transparent outline-none rounded-xl" placeholder="جستجو..." type="text" onChange={(e) => setSearchInput(e.target.value.trim())}></input>
          <button type="submit" className="px-3 transition-colors cursor-pointer hover:text-yellow-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
}
