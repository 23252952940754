import { useState } from "react";
import Navbar from "./components/Header/Navbar";
import MoviesBox from "./components/Main/MoviesBox";

function App() {
  const [query, setQuery] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="flex flex-col w-full h-screen p-5 space-y-5 bg-primary">
      <header className="h-fit">
        <Navbar onSearchQuery={setSearchQuery} />
      </header>

      <main className="pb-5 grow">
        <MoviesBox query={query} onChangePage={setQuery} searchQuery={searchQuery} onSearchQuery={setSearchQuery} />
      </main>
    </div>
  );
}

export default App;
