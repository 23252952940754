// import { useEffect, useState } from "react";

export default function MovieItems({ movie }) {
  // const [isHover, setIsHover] = useState(false);

  // function getId(id) {
  //   setIsHover(true);
  //   return id;
  // }

  // useEffect(() => {
  //   async function fetchMovieDetails() {
  //     try {
  //       console.log(movie.id);
  //       const res = await fetch(`https://moviesapi.ir/api/v1/movies/${movie.id}`);
  //       const data = await res.json();
  //       console.log(data);
  //     } catch (err) {
  //     } finally {
  //       setIsHover(false);
  //     }
  //   }
  //   fetchMovieDetails();
  // }, [isHover, movie]);

  return (
    <div
      className="relative flex flex-col items-center justify-center text-2xl *:transition cursor-pointer rounded-2xl h-fit font-Plasma hover:inner_shadow outer__shadow group">
      <img src={movie.poster} alt={movie.title} className="object-fill w-full h-fit rounded-2xl" />
      <span
        dir="ltr"
        className="absolute bottom-0 w-full px-5 py-2 overflow-hidden text-lg text-center translate-y-12 font-Dosis whitespace-nowrap text-ellipsis text-secondary group-hover:opacity-50"
      >
        {movie.title}
      </span>
      <div className="absolute top-0 items-center justify-center hidden w-full h-full transition bg-primary/80 rounded-2xl group-hover:flex">
        <span>hov</span>
      </div>
    </div>
  );
}
