import React from "react";
import ReactPaginate from "react-paginate";

export default function PaginatedItems({ itemsPerPage, query, onChangePage, onSearchQuery }) {
  const handlePageClick = (event) => {
    if (query > 1 && event.isPrevious) {
      onChangePage(query - 1);
    } else if (query < itemsPerPage && event.isNext) {
      onChangePage(query + 1);
    } else {
      onChangePage((query = event.nextSelectedPage + 1));
    }
    onSearchQuery("");
  };

  return (
    <>
      <ReactPaginate
        className="flex flex-row-reverse justify-center gap-x-2 md:gap-x-4 w-full *:text-secondary *:transition *:rounded-2xl *:outer__shadow *:cursor-pointer select-none *:border-secondary *:size-10 *:grid *:place-content-center  *:text-xs *:md:text-base"
        activeClassName="border-2"
        previousClassName="!w-16 hover:inner__shadow hover:text-yellow-600"
        nextClassName="!w-16 hover:inner__shadow hover:text-yellow-600"
        disabledClassName="opacity-50"
        breakLabel="..."
        nextLabel="بعدی"
        onClick={handlePageClick}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        pageCount={itemsPerPage}
        previousLabel="قبلی"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
